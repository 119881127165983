<template>
    <div class="box w-24 flex-none flex items-center justify-center">
        <div class="text-center" v-if="row.hasOwnProperty('Lost')">
            <!-- <div class="text-xl text-font-gray mb-8">Total</div> -->
            <div class="text-2xl">{{ row.Lost.total.oportunity | numberFormat() }}</div>
            <div class="text-font-gray">{{ row.Lost.total.sales | reduceBigNumbers(2) }}€</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BallLostTotal",
        props: ['row']
    }
</script>